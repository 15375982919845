<template>
  <div id="financial-receipt">
    <vs-tabs id="financial-receip-internal-tab" position="left" color="primary">
      <vs-tab label="Carnê">
        <div class="tab-text">
          <config-book-pay />
        </div>
      </vs-tab>
      <vs-tab label="Juros e Multa">
        <div class="tab-text">
          <interest-list :allow-search="true" />
        </div>
      </vs-tab>
      <vs-tab label="Tipo de Pagamento">
        <div class="tab-text">
          <type-payment-list :allow-search="true" />
        </div>
      </vs-tab>
      <vs-tab label="Parcelamento">
        <div class="tab-text">
          <parcel-list :allow-search="true" />
        </div>
      </vs-tab>
      <vs-tab label="Máquina de Cartão">
        <div class="tab-text">
          <financial-card-list :allow-search="true" />
        </div>
      </vs-tab>
      <vs-tab label="Quitar Automaticamente">
        <div class="tab-text">
          <type-down-pay-list :allow-search="true" />
        </div>
      </vs-tab>
      <vs-tab label="Contas">
        <div class="tab-text">
          <account-list :allow-search="true" />
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import SpecieTypePaymentSelect from '@/components/financial/specieTypePayment/SpecieTypePaymentSelect'
import TypeDownPayList from '@/components/financial/typeDownPay/TypeDownPayList'
import TypePaymentList from '@/components/financial/typePayment/TypePaymentList'
import FinancialCardList from '@/components/financial/financialCard/FinancialCardList'
import AccountList from '@/components/financial/account/AccountList'
import ParcelList from '@/components/financial/parcel/ParcelList'
import InterestList from '@/components/financial/interest/InterestList'
import ConfigBookPay from '@/components/financial/book-pay/ConfigBookPay'

export default {
  components: {
    TypeDownPayList,
    FinancialCardList,
    AccountList,
    TypePaymentList,
    InterestList,
    ParcelList,
    SpecieTypePaymentSelect,
    ConfigBookPay
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style>
.ck-editor__editable {
  min-height: 650px;
}
</style>
